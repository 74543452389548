import { ethers } from 'ethers'
import Vue from 'vue'
import store from '@/store'
import chainAddresses from '@/libs/blockchain_contracts/chainAddresses'

const priceConversionCalculatorABI = require('./ABI/PriceConversionCalculator.sol/PriceConversionCalculator.json')

const priceConversionCalculatorContract = () => new ethers.Contract(
  chainAddresses.price_conversion_calculator,
  priceConversionCalculatorABI,
  store.state.chainData.readProvider
)

Vue.prototype.$priceConversionCalculatorContract = priceConversionCalculatorContract

export default priceConversionCalculatorContract
