import Vue from 'vue'

const edificationLevels = () => {
    return [
        {
            minCommissions: 0,
            minNFTLevel: 0,
            name: 'Beginner Influencer'
        },
        {
            minCommissions: 1000,
            minNFTLevel: 0,
            name: '1 Star Influencer'
        },
        {
            minCommissions: 3000,
            minNFTLevel: 3,
            name: '2 Star Influencer'
        },
        {
            minCommissions: 7500,
            minNFTLevel: 3,
            name: '3 Star Influencer'
        },
        {
            minCommissions: 15000,
            minNFTLevel: 4,
            name: '4 Star Influencer'
        },
        {
            minCommissions: 30000,
            minNFTLevel: 4,
            name: '5 Star Influencer'
        },
        {
            minCommissions: 50000,
            minNFTLevel: 5,
            name: '6 Star Influencer'
        },
        {
            minCommissions: 100000,
            minNFTLevel: 5,
            name: '7 Star Influencer'
        },
        {
            minCommissions: 300000,
            minNFTLevel: 6,
            name: '8 Star Influencer'
        },
        {
            minCommissions: 600000,
            minNFTLevel: 7,
            name: '9 Star Influencer'
        },
        {
            minCommissions: 1000000,
            minNFTLevel: 9,
            name: '10 Star Influencer'
        },
        {
            minCommissions: 2000000,
            minNFTLevel: 9,
            name: 'Royal Star Influencer'
        },
        {
            minCommissions: 5000000,
            minNFTLevel: 9,
            name: 'Ambassador Star Influencer'
        },
        {
            minCommissions: 10000000,
            minNFTLevel: 9,
            name: 'Presidential Star Influencer'
        }
    ]
}

Vue.prototype.$edificationLevels = edificationLevels

export default edificationLevels
