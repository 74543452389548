import { ethers } from 'ethers'
import Vue from 'vue'
import store from '@/store'
import chainAddresses from '@/libs/blockchain_contracts/chainAddresses'

const os2TicketSales = require('./ABI/IOceanSummitII.sol/IOceanSummitII.json')

const os2TicketSalesContract = () => new ethers.Contract(
  chainAddresses.os_ii_ticket_sales,
  os2TicketSales,
  store.state.chainData.readProvider
)

Vue.prototype.$os2TicketSalesContract = os2TicketSalesContract

export default os2TicketSalesContract
