import Vue from 'vue'

const qualificationLevel = {
    NoBonus: 0,
    Silver1: 1,
    Silver2: 2,
    Gold1: 3,
    Gold2: 4,
    GoldHumpback: 5,
    Platinum1: 6,
    Platinum2: 7,
    Platinum3: 8,
    PlatinumDubai: 9
}
Vue.prototype.$QualificationLevel = qualificationLevel

export default qualificationLevel
