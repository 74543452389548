import { ethers } from 'ethers'
import Vue from 'vue'
import chainAddresses from '@/libs/blockchain_contracts/chainAddresses'
import store from '@/store'

const contractAddress = chainAddresses.edification
const edificationABI = require('./ABI/Edification.sol/Edification.json')

const edificationContract = () => new ethers.Contract(
        contractAddress,
        edificationABI,
        store.state.chainData.readProvider
    )

Vue.prototype.$edificationContract = edificationContract

export default edificationContract
