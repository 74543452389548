<template>
    <div class="text-center">
        <span class="text-xl">{{ $t('connect_wallet') }}</span>
        <div class="flex flex-wrap gap-2 justify-center mt-2">
            <div
                class="connect-button p-1 flex flex-row justify-center items-center"
                @click="$store.dispatch('connectMetamask')"
                v-if="hasMetamask"
            >
                <img :src="require('@/assets/images/metamask_logo.svg')" />
                <span class="lg:text-lg ml-2">Metamask</span>
            </div>
            <div
                class="connect-button p-1 flex flex-row justify-center items-center"
                @click="$store.dispatch('connectWalletConnect')"
            >
                <img
                    :src="require('@/assets/images/wallet_connect_logo.svg')"
                />
                <span class="lg:text-lg ml-2">WalletConnect</span>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                hasMetamask: true
            }
        },
        created() {
            this.hasMetamask = !!window.ethereum
        }
    }
</script>
<style scoped>
.connect-button img {
    max-width: 30px;
}

.connect-button:hover {
  background-color: #700e0f;
}

.connect-button {
    font-weight: 500;
    background: #ba141a;
    white-space: nowrap;
    position: relative;
    cursor: pointer;
    color: white;
    width: 200px;
    transition-duration: 250ms;
    border-radius: 10px;
    height: 50px;
}
</style>
