<template>
    <div id="main-view">
        <div>
            <template v-if="$store.state.currentTimestamp > launchTimestamp">
                <div class="w-full pb-4 grid grid-cols-2 md:grid-cols-3">
                    <div class="h-full order-first">
                        <back-button v-if="selectedPass !== '' || isGiftTicket || upgradeToGold"  @click.native="onBackClicked" />
                    </div>
                    <div
                        class="order-last md:order-none col-span-2 md:col-span-1 mt-2 md:mt-0 h-full flex flex-wrap items-center justify-center px-4"
                    >
                        <ChangeNFT v-if="
                            this.$store.state.chainData.address &&
                                this.$store.state.displayData &&
                                this.$store.state.displayData.accountNFTs &&
                                this.$store.state.displayData.accountNFTs.length > 1"/>
                    </div>
                    <connect-wallet-menu  class="order-2 md:order-last col-start-2 md:col-start-3 justify-self-end"
                    />
                </div>
            </template>
            <Header class="mb-8" @click="homeClicked"/>

            <div class="flex flex-row justify-center px-0">
                <div v-if="!$store.getters.userSignedIn">
                    <connect-wallet-component/>
                </div>
                <div v-else-if="!$store.getters.userHasNFT">
                    <a href="https://app.metfi.io">
                        <custom-button>{{ $t('summit.buy_metfi_nft_to_participate') }}</custom-button>
                    </a>
                </div>
                <div v-else-if="($store.state.accountData.ticketInfo.id.eq(0) && $store.state.accountData.ticketInfoAPI.qualified_level < 5 ) || isGiftTicket || upgradeToGold" class="flex flex-col justify-center">

                    <h1 class="text-center uppercase font-bold text-3xl text-red mb-8">{{ $t('tickets.grab_your_tickets_now') }}</h1>

                    <div v-if="selectedPass">
                        <silver-pass-component v-if="selectedPass === 'silver'" selected @on-pass-selected="onPassSelected" @on-buy-now-clicked="buyTicket" :must-be-gift="isGiftTicket"/>
                        <gold-pass-component v-else-if="selectedPass === 'gold'" selected @on-pass-selected="onPassSelected" @on-buy-now-clicked="buyTicket" :must-be-gift="isGiftTicket" :is-upgrade="upgradeToGold"/>
                        <!--                        <platinum-pass-component v-else-if="selectedPass === 'platinum'" selected @on-pass-selected="onPassSelected" @on-buy-now-clicked="buyTicket" :must-be-gift="isGiftTicket"/>-->
                    </div>
                    <div v-else>
                        <div class="flex flex-col justify-center md:grid md:grid-cols-2 gap-4">
                            <div class="col-span-1 h-full">
                                <silver-pass-component @on-pass-selected="onPassSelected"/>
                            </div>

                            <div class="col-span-1">
                                <gold-pass-component @on-pass-selected="onPassSelected"/>
                            </div>

                            <!-- <div class="col-span-2">
                                <platinum-pass-component @on-pass-selected="onPassSelected"/>
                            </div> -->

                        </div>
                    </div>
                </div>
                <div v-else>
                    <owns-ticket-component @on-gift-ticket-clicked="onGiftTicketClicked"
                                           @on-upgrade-to-gold-clicked="onUpgradeToGoldClicked"
                                           class="mt-4"/>
                </div>
            </div>
            <div class="px-0 lg:px-16 xl:px-20 2xl:px-32" v-if="$store.getters.userSignedIn && $store.getters.userHasNFT">
                <!--                <FreeTicketPromotion :myNFTInfo="myNFTInfo" :ticketsLeft="ticketsLeft"/>-->
                <Top10Component/>
            </div>
        </div>
    </div>
</template>

<script>
    import ConnectWalletComponent from '@/components/ConnectWalletComponent.vue'
    import CustomButton from '@/components/CustomButton.vue'
    import os2TicketSalesContract from '@/libs/blockchain_contracts/os2TicketSales'
    import SilverPassComponent from '@/views/components/SilverPassComponent.vue'
    import GoldPassComponent from '@/views/components/GoldPassComponent.vue'
    // import PlatinumPassComponent from '@/views/components/PlatinumPassComponent.vue'
    import OwnsTicketComponent from '@/views/components/OwnsTicketComponent.vue'
    import Header from '@/components/Header.vue'
    import ConnectWalletMenu from '@/components/navbar/ConnectWalletMenu.vue'
    import 'vue-select/src/scss/vue-select.scss'
    import BackButton from '@/components/BackButton.vue'
    import accountToken from '@/libs/blockchain_contracts/accountToken'
    import {ethers} from 'ethers'
    // import FreeTicketPromotion from '@/components/FreeTicketPromotion.vue'
    import Top10Component from '@/components/Leaderboard/Top10Component.vue'
    import platformViewContract from '@/libs/blockchain_contracts/platformView'
    import {captureException} from '@sentry/vue'
    import axios from '@/libs/axios'

    export default {
        name: 'MainView',
        components: {
            Top10Component,
            // FreeTicketPromotion,
            BackButton,
            OwnsTicketComponent,
            // PlatinumPassComponent,
            GoldPassComponent,
            SilverPassComponent,
            CustomButton,
            ConnectWalletComponent,
            Header,
            ConnectWalletMenu,

            ChangeNFT: () => import('@/components/navbar/ChangeNFT.vue') // lazy loading

        },
        data() {
            return {
                selectedPass: '',
                isGiftTicket: false,
                launchTimestamp: 1685793600,
                isQualified: true,
                isRefunded: false,
                upgradeToGold: false,
                myNFTInfo: null,
                ticketsLeft: {
                    '0': {
                        '0': 268
                    },
                    '1': {
                        '0': 72
                    },
                    '2': {
                        '0': 21,
                        '1': 7,
                        '2': 18
                    }
                }
            }
        },
        methods: {
            onGiftTicketClicked() {
                this.selectedPass = ''
                this.isGiftTicket = true
            },
            onUpgradeToGoldClicked() {
                this.selectedPass = 'gold'
                this.upgradeToGold = true
            },
            onBackClicked() {
                // const hasTicket = this.$store.state.accountData.ticketInfo && Number(this.$store.state.accountData.ticketInfo.id) !== 0

                if (this.selectedPass !== '') {
                    this.selectedPass = ''
                    return
                }

                this.isGiftTicket = false
                this.upgradeToGold = false
            },
            homeClicked() {
                this.selectedPass = ''
                this.isGiftTicket = false
            },
            async validateGiftParameters(giftType, giftValue) {
                if (giftType === this.$GiftOption.USERNAME) {
                    try {
                        Number(await accountToken().getAccountByReferral(giftValue))
                    } catch (e) {
                        captureException(e)
                        this.$printError('Error Getting Gift Recipient. Check the Username and Try Again')
                        return false
                    }
                } else if (giftType === this.$GiftOption.ADDRESS) {
                    const recipientNumberOfNFTs = Number(await accountToken().balanceOf(giftValue))
                    if (recipientNumberOfNFTs === 0) {
                        this.$printError('Address does not contain NFT.')
                        return false
                    } else if (recipientNumberOfNFTs >= 2) {
                        this.$printError('Wallet Address Not Valid. It already holds more than one NFT. Enter Username or NFT ID Instead.')
                        return false
                    }
                } else if (giftType === this.$GiftOption.NFT_ID) {
                    try {
                        const nftData = await platformViewContract().getNFTData(Number(giftValue))
                        if (nftData && nftData.liquidated) {
                            this.$printError('NFT ID Does Not Exist')
                            return false
                        }
                    } catch (e) {
                        captureException(e)
                        this.$printError('NFT ID Does Not Exist')
                        return false
                    }
                }

                return true
            },
            async buyTicket({amount, address, isBNB, ticketType, isGift, giftType, giftValue, isUpgrade}) {
                try {
                    this.$store.commit('START_LOADING')
                    let tx = null

                    if (isUpgrade) {
                        tx = await os2TicketSalesContract().connect(this.$store.state.chainData.signer)['upgradeTicket(uint256,address,uint256)'](this.$store.getters.selectedNFT.ID, address, amount, {value: isBNB ? amount : 0})
                    } else if (isGift) {
                        const valid = await this.validateGiftParameters(giftType, giftValue)
                        if (!valid) return

                        if (giftType === this.$GiftOption.USERNAME) {
                            tx =  await os2TicketSalesContract().connect(this.$store.state.chainData.signer)['buyTicket(uint8,uint256,string,address,uint256)'](ticketType, this.$store.getters.selectedNFT.ID, giftValue, address, amount, {value: isBNB ? amount : 0})
                        } else if (giftType === this.$GiftOption.ADDRESS) {
                            tx =  await os2TicketSalesContract().connect(this.$store.state.chainData.signer)['buyTicket(uint8,uint256,address,address,uint256)'](ticketType, this.$store.getters.selectedNFT.ID, giftValue, address, amount, {value: isBNB ? amount : 0})
                        } else if (giftType === this.$GiftOption.NFT_ID) {
                            const receiver = ethers.BigNumber.from(giftValue)
                            tx =  await os2TicketSalesContract().connect(this.$store.state.chainData.signer)['buyTicket(uint8,uint256,uint256,address,uint256)'](ticketType, this.$store.getters.selectedNFT.ID, receiver, address, amount, {value: isBNB ? amount : 0})
                        }
                    } else {
                        tx = await os2TicketSalesContract().connect(this.$store.state.chainData.signer)['buyTicket(uint8,uint256,uint256,address,uint256)'](ticketType, this.$store.getters.selectedNFT.ID,  this.$store.getters.selectedNFT.ID, address, amount, {value: isBNB ? amount : 0})
                    }
                    if (!tx) {
                        let action = 'buying'
                        if (isGift) action = 'gifting'
                        else if (isUpgrade) action = 'upgrading'
                        this.$printError(`There was an error ${action} ticket.`)
                        return
                    }

                    await tx.wait()

                    try {
                        window._paq.push(['trackEvent', 'Ticket', isUpgrade ? 'Upgrade' : 'Purchase', this.getTicketFromType(ticketType), this.getTicketPriceFromType(ticketType)])
                    } catch (e) {
                        captureException(e)
                    }

                    await Promise.all([this.$store.dispatch('loadTicketDataFromChain'), this.$store.dispatch('refreshTicketsInfo')])

                    let action = 'bought'
                    if (isGift) action = 'gifted'
                    else if (isUpgrade) action = 'upgraded'
                    this.$printSuccess(`Ticket ${action} successfully.`)
                    if (isGift) {
                        this.isGiftTicket = false
                    }
                    this.selectedPass = ''
                    this.upgradeToGold = false
                    this.isGiftTicket = false
                } catch (e) {
                    this.$printError(`There was an error ${isGift ? 'gifting' : 'buying'} ticket.`)
                    captureException(e)
                } finally {
                    this.$store.commit('STOP_LOADING')
                }
            },
            onPassSelected(pass) {
                this.selectedPass = pass
            },
            getTicketFromType(type) {
                switch (type) {
                case 0:
                    return 'Silver'
                case 1:
                    return 'Gold'
                case 2:
                    return 'Platinum'
                default:
                    return ''
                }
            },
            getTicketPriceFromType(type) {
                if (!this.$store.state.ticketsInfo || !this.$store.state.ticketsInfo.ticketsPrices || !this.$store.state.ticketsInfo.ticketsPrices[type]) {
                    return ''
                }

                return ethers.utils.formatUnits(this.$store.state.ticketsInfo.ticketsPrices[type])
            },
            async loadMyNFTInformation() {

                if (!this.nftId) return
                try {
                    this.$store.commit('START_LOADING')

                    const res = await axios.get(`/api/leaderboard/v1/my/${this.nftId}`)
                    this.myNFTInfo = res.data
                    this.$store.commit('SET_TICKET_API_INFO', res.data)
                } catch (e) {
                    captureException(e)
                } finally {
                    this.$store.commit('STOP_LOADING')

                }
            },
            async loadTicketsLeft() {
                try {
                    this.$store.commit('START_LOADING')
                    const res = await axios.get('/api/leaderboard/v1/free_tickets_left')
                    this.ticketsLeft = res.data
                } catch (e) {
                    captureException(e)
                } finally {
                    this.$store.commit('STOP_LOADING')
                }
            }
        },
        computed: {
            nftId() {
                if (!this.$store.getters.selectedNFT) return 0
                return this.$store.getters.selectedNFT.ID
            }
        },
        watch: {
            '$store.state.displayData.selectedNFT'() {
                this.homeClicked()
            },
            async 'nftId'() {
                await this.loadMyNFTInformation()
            }
        },
        async mounted() {
            const params = new Proxy(new URLSearchParams(window.location.search), {
                get: (searchParams, prop) => searchParams.get(prop)
            })

            if (params.ticketType && [1, 2, 3].includes(Number(params.ticketType))) {
                const ticketType = Number(params.ticketType)
                if (ticketType === 1) this.selectedPass = 'silver'
                else if (ticketType === 2) this.selectedPass = 'gold'
                else if (ticketType === 3) this.selectedPass = 'platinum'
            }

            if (params.devCustomAddress) {
                this.$store.state.chainData.address = params.devCustomAddress
                this.$store.dispatch('loadDataFromChain')
            }

            if (params.devCustomUser) {
                console.log('custom user', params.devCustomUser)
                this.$store.state.chainData.address = await accountToken().ownerOf(Number(await accountToken().getAccountByReferral(params.devCustomUser)))
                this.$store.dispatch('loadDataFromChain')
            }

            if (params.devCustomNFT) {
                console.log('custom nft', params.devCustomNFT)
                this.$store.state.chainData.address = await accountToken().ownerOf(Number(params.devCustomNFT))
                this.$store.dispatch('loadDataFromChain')
            }

            await Promise.all([this.loadMyNFTInformation(), this.loadTicketsLeft()])

        }
    }
</script>
<style scoped>
#main-view {
/*  background-image: url("@/assets/images/bg-1.webp");
  background-position: top center;
  background-repeat: no-repeat;
  width: 100%;
  background-size: auto 800px;*/
  min-height: calc(100vh - 60px - 70px);
}
@media (max-width: 768px) {
  #main-view {
    min-height: calc(100vh - 60px - 120px);
  }
}
</style>
