<template>
    <div class="pillMenuItem p-2 w-full flex flex-row items-center"
         @click="$emit('click', route)">
        <span class="text-base lg:text-lg px-2"><slot></slot></span>
    </div>
</template>
<script>
    export default {
        props: {
            route: {
                type: String
            }
        }
    }
</script>
<style scoped>
.pillMenuItem {
  transition-duration: 250ms;
  border-radius: 10px;
  cursor: pointer;
  min-height: 43px;
}

.pillMenuItem:hover {
  background: #700e0f;
}
</style>
