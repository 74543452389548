import Vue from 'vue'
import Toast from 'vue-toastification'
import 'vue-toastification/dist/index.css'

Vue.use(Toast, {
    hideProgressBar: true,
    closeOnClick: false,
    icon: true,
    timeout: 3000,
    transition: 'Vue-Toastification__fade'
})

Vue.prototype.$printError = function(message) {
    this.$toast.error(message)
}

Vue.prototype.$printWarning = function(message) {
    this.$toast.warning(message)
}

Vue.prototype.$printSuccess = function(message) {
    this.$toast.success(message)
}
