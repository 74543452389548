import Vue from 'vue'

const currencies = {
    'USDT': {
        name: 'USDT',
        long_name: 'Tether',
        address: '0x55d398326f99059fF775485246999027B3197955',
        decimals: 18,
        icon: 'usdt.svg'
    },
    'BUSD': {
        name: 'BUSD',
        long_name: 'Binance USD',
        address: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
        decimals: 18,
        icon: 'busd.svg'
    },
    'USDC': {
        name: 'USDC',
        long_name: 'USC Coin',
        address: '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
        decimals: 18,
        icon: 'usdc.svg'
    },
    'BTCB': {
        name: 'BTCB',
        long_name: 'Bitcoin BEP2',
        address: '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
        decimals: 18,
        icon: 'btcb.svg'
    },
    'WETH': {
        name: 'WETH',
        long_name: 'Wrapped Ether',
        address: '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
        decimals: 18,
        icon: 'weth.svg'
    },
    'WBNB': {
        name: 'WBNB',
        long_name: 'Wrapped BNB',
        address: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
        decimals: 18,
        icon: 'wbnb.svg'
    }
}
Vue.prototype.$currencies = currencies
export default currencies
