<template>
    <div>
        <confirm-ticket :myNFT="myNFT" v-if="dataLoaded && myNFT && !myNFT.confirmed" @load-data="loadData"/>

        <div class="flex flex-col justify-center items-center w-fit mx-auto">

            <div class="flex max-w-2xl flex-col">
                <custom-button class="mt-4" @click="onGiftTicketClicked">Do you want to gift a ticket?</custom-button>
                <template v-if="hasSilverTicket && isQualified && isQualifiedOnChain">
                    <div class="flex flex-col justify-center pt-4">
                        <div class="w-full text-center"><span class="text-red font-bold">OR</span></div>
                        <custom-button class="mt-4" @click="onUpgradeToGoldClicked">Do you want to upgrade to gold?</custom-button>
                    </div>
                </template>
            </div>

            <card border="white" class="w-full my-4 mx-2" animate-background
                  body-class="max-w-2xl" :background="ownsTicketCardBackground">
                <div class="flex flex-col justify-center items-center">
                    <span class="font-bold text-3xl text-center">YOUR TICKET</span>
                    <span class="font-bold text-lg text-center text-red">for Ocean Summit II Istanbul</span>
                    <div class="border-top-dotted w-full my-2"></div>
                    <span class="font-bold text-lg"><span>NFT ID:</span> <span class="text-red">{{$store.getters.selectedNFT.ID}}</span></span>
                    <span class="font-bold text-lg"><span>Username:</span> <span class="text-red">{{myNFT ? myNFT.username : ''}}</span></span>
                    <span class="font-bold text-lg"><span>Pass Type:</span> <span class="text-red">{{ ticketName }}</span></span>
                    <span class="font-bold text-lg"><span>Qualified: </span>
                        <!--                  <sup>1</sup>-->
                        <img class="inline w-5 pb-0.5" v-if="isQualified" src="@/assets/images/check.svg"/>
                        <img class="inline w-5 pb-0.5" v-else src="@/assets/images/x.svg"/>
                    </span>
                    <span v-if="showRefund" class="font-bold text-lg"><span>Refunded: </span>
                        <!--                  <sup>2</sup>-->
                        <img class="inline w-5 pb-0.5" v-if="isRefunded" src="@/assets/images/check.svg"/>
                        <img class="inline w-5 pb-0.5" v-else src="@/assets/images/x.svg"/>
                    </span>
                    <span class="font-bold text-lg"><span>Date Bought:</span> <span class="text-red">{{ dateBought }}</span></span>
                    <span class="font-bold text-lg"><span>Ticket Confirmed: </span>
                        <!--                  <sup>1</sup>-->
                        <img class="inline w-5 pb-0.5" v-if="myNFT && myNFT.confirmed" src="@/assets/images/check.svg"/>
                        <img class="inline w-5 pb-0.5" v-else src="@/assets/images/x.svg"/>
                    </span>
                    <span class="font-bold text-lg" v-if="myNFT && myNFT.confirmed"><span>Ticket ID:</span> <span class="text-red">{{ myNFT && myNFT.ticket_id }}</span></span>

                </div>

                <div v-if="isQualified" class="w-full">
                    <div class="border-top-dotted w-full my-2"></div>
                    <div class="flex flex-col items-center w-full">
                        <span class="font-bold text-lg"><span>METFI Bonus:</span> <span class="text-red">{{ metfiBonus }} METFI</span></span>
                        <span class="font-bold text-lg"><span>USDT Travel Bonus:</span> <span class="text-red">{{ travelBonus }} USDT</span></span>
                    </div>
                </div>

            <!--            <div class="text-left w-full text-sm pt-4 leading-3">
                <div>
                    <span class="leading-3"><span class="font-bold">1</span> - In case someone purchases the Ticket AFTER early bird time we don’t refund them the FULL after early bird price but still ONLY the early Bird price! reads in fine prints</span>
                </div>
                <div class="pt-1">
                    <span class="leading-3"><span class="font-bold">2</span> - In case someone purchases the Ticket AFTER early bird time we don’t refund them the FULL after early bird price but still ONLY the early Bird price! reads in fine prints</span>
                </div>
            </div>-->
            </card>

        </div>
        <div class="pt-2 flex justify-center flex-col items-center" v-if="dataLoaded && myNFT && myNFT.confirmed">
            <div>
                <p class="text-base"><span class="text-red">*</span>Take a screenshot of this confirmation page and bring it to the event registration desk.</p>
            </div>
            <div>
                <p class="text-base"><span class="text-red">*</span>Do not change your MetFi <span class="font-bold">USERNAME</span> before registering at the event.</p>
            </div>
        </div>
    </div>

</template>
<script>
    import CustomButton from '@/components/CustomButton.vue'
    import Card from '@/components/Card.vue'
    import ConfirmTicket from '@/views/components/ConfirmTicket.vue'
    import {captureException} from '@sentry/vue'

    export default {
        components: {ConfirmTicket, Card, CustomButton},
        data() {
            return {
                upgradeToGold: false,
                myNFT: undefined,
                dataLoaded: false
            }
        },
        methods: {
            onGiftTicketClicked() {
                this.$emit('on-gift-ticket-clicked')
            },
            onUpgradeToGoldClicked() {
                this.$emit('on-upgrade-to-gold-clicked')
            },
            async loadData()  {
                try {
                    this.$store.commit('START_LOADING')
                    const res = await this.$http.get(`/api/leaderboard/v1/my/${this.$store.getters.selectedNFT.ID}`)
                    this.myNFT = res.data
                    this.dataLoaded = true
                } catch (e) {
                    captureException(e)
                    this.$printError('There was an error loading data!')
                } finally {
                    this.$store.commit('STOP_LOADING')
                }
            }
        },
        computed: {
            ownsTicketCardBackground() {
                const ticketType = this.ticketType
                if (ticketType === undefined || ticketType === null || ticketType === -1) {
                    return ''
                }

                switch (Number(ticketType)) {
                case 0:
                    return 'silver-gradient'
                case 1:
                    return 'gold-gradient'
                case 2:
                    return 'silver-gradient'
                default:
                    return ''
                }
            },
            ticketName() {
                const ticketType = this.ticketType
                if (ticketType === undefined || ticketType === null || ticketType === -1) {
                    return ''
                }

                switch (Number(ticketType)) {
                case 0:
                    return 'Silver'
                case 1:
                    return 'Golden'
                case 2:
                    return 'Platinum'
                default:
                    return ''
                }
            },
            ticketType() {

                if (!this.$store.state.accountData.ticketInfo || !this.$store.state.accountData.ticketInfo.ticket) return -1
                if (this.$store.state.accountData.ticketInfoAPI) {
                    return Math.max(this.$store.state.accountData.ticketInfoAPI.ticket_type, this.$store.state.accountData.ticketInfo.ticket.ticketType)
                } else {
                    return this.$store.state.accountData.ticketInfo.ticket.ticketType
                }

            },
            showRefund() {
                if (this.$store.state.accountData.ticketInfoAPI) {
                    return this.$store.state.accountData.ticketInfoAPI.expected_refund_amount > 0
                } else {
                    return false
                }
            },
            isQualified() {
                if (this.$store.state.accountData.ticketInfoAPI) {
                    return this.$store.state.accountData.ticketInfoAPI.qualified
                }
                if (!this.$store.state.accountData.ticketInfo || !this.$store.state.accountData.ticketInfo.ticket || !this.$store.state.accountData.ticketInfoAPI) return false
                return  this.$store.state.accountData.ticketInfo.ticket.qualified
            },
            isQualifiedOnChain() {
                if (!this.$store.state.accountData.ticketInfo || !this.$store.state.accountData.ticketInfo.ticket || !this.$store.state.accountData.ticketInfoAPI) return false
                return  this.$store.state.accountData.ticketInfo.ticket.qualified
            },
            isRefunded() {
                if (!this.$store.state.accountData.ticketInfo || !this.$store.state.accountData.ticketInfo.ticket) return false
                return this.$store.state.accountData.ticketInfo.ticket.refunded
            },
            dateBought() {
                if (!this.$store.state.accountData.ticketInfo || !this.$store.state.accountData.ticketInfo.ticket || !this.$store.state.accountData.ticketInfo.ticket.boughtAtTimestamp) return '/'
                return this.$dayjs(Number(this.$store.state.accountData.ticketInfo.ticket.boughtAtTimestamp * 1000)).format('MM/DD/YYYY')
            },
            travelBonus() {
                if (!this.isQualified) return 0
                if (this.$store.state.accountData.ticketInfoAPI) {
                    return this.$store.state.accountData.ticketInfoAPI.assigned_rewards.travel_bonus
                }
                if (!this.$store.state.accountData.ticketInfo ||
                    !this.$store.state.accountData.ticketInfo.ticket ||
                    !this.$store.state.accountData.ticketInfo.ticket.assignedRewards ||
                    this.$store.state.accountData.ticketInfo.ticket.assignedRewards.length !== 2) return 0
                return this.$formatDollarsFromRaw(this.$store.state.accountData.ticketInfo.ticket.assignedRewards[0], 0)
            },
            metfiBonus() {
                if (!this.isQualified) return 0
                if (this.$store.state.accountData.ticketInfoAPI) {
                    return this.$store.state.accountData.ticketInfoAPI.assigned_rewards.metfi_bonus
                }
                if (!this.$store.state.accountData.ticketInfo ||
                    !this.$store.state.accountData.ticketInfo.ticket ||
                    !this.$store.state.accountData.ticketInfo.ticket.assignedRewards ||
                    this.$store.state.accountData.ticketInfo.ticket.assignedRewards.length !== 2) return 0
                return this.$formatDollarsFromRaw(this.$store.state.accountData.ticketInfo.ticket.assignedRewards[1], 0)
            },
            hasSilverTicket() {
                return this.ticketType === 0
            }
        },
        async mounted() {
            await this.loadData()
        }
    }
</script>
<style scoped>
.description{
  backdrop-filter: blur(4.2px);
  /*border-left: 1px solid white;*/
}
table {
  color: #1074b0;
}
th {
  border-top: 1px solid #54c6e0;
  border-bottom: 1px solid #54c6e0;
  border-right: 1px solid #54c6e0;
}
td {
  border-bottom: 1px solid #54c6e0;
  border-right: 1px solid #54c6e0;
  text-align: center;
  padding-top: 10px;
}
td:last-child,
th:last-child {
  border-right: none;
}
</style>
