import Vue from 'vue'
import VueI18n from 'vue-i18n'

const messages = {
    en: require('./locales/en.json'),
    es: require('./locales/es.json'),
    it: require('./locales/it.json'),
    fr: require('./locales/fr.json'),
    zh: require('./locales/zh.json')
}

Vue.use(VueI18n)

const lastLanguage = localStorage.getItem('l')

const i18n = new VueI18n({
    locale: lastLanguage ?? 'en', // set locale
    messages // set locale messages
})

export default i18n
