import Vue from 'vue'
const appStatus = {
    DEFAULT: 1,
    ACTIONS_LOCKED: 2,
    LIVE_DRAW_ACTIVE: 3
}

Vue.prototype.$AppStatus = appStatus

export default appStatus
