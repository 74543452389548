import dayjs from 'dayjs'
import Vue from 'vue'

Vue.prototype.$dayjs = dayjs
Vue.prototype.$formatDate = (date, showTime = true) => {
    if (!date || !dayjs(date).isValid) return '/'

    const formatToken = showTime ? 'YYYY/MM/DD HH:mm' : 'YYYY/MM/DD'
    return dayjs(date).format(formatToken)
}
