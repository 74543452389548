import Vue from 'vue'
const batchStatus = {
    Pending: 1,
    Won: 2,
    Invalid: 3,
    CanReset: 4,
    CorrectAnswer: 5,
    CanNotReset: 6
}

Vue.prototype.$BatchStatus = batchStatus

export { batchStatus }
