import Vue from 'vue'
import App from './App.vue'

import '@/assets/css/main.css'
import '@/assets/css/aurora.css'
import '@/libs/helpers'
import '@/libs/toastification'
import '@/libs/dayjs'
import '@/libs/enums/index'
import '@/libs/currencies'
import '@/libs/edification_levels'
import '@/libs/nft_levels'
import '@/libs/sentry'
import axios from '@/libs/axios'
import { initializeStore } from '@/store'

import i18n from '@/libs/i18n'

import Toast from 'vue-toastification'
import 'vue-toastification/dist/index.css'

Vue.config.productionTip = false

import VueMatomo from 'vue-matomo'
import {captureException} from '@sentry/vue'

async function initialize() {
    try {
        const configResponse = await axios.get('/config.json')
        Vue.prototype.appConfig = configResponse.data
    } catch (e) {
        captureException(e)
        return
    }

    Vue.use(Toast)

    Vue.use(VueMatomo, {
        host: 'https://matomo.metfi.io',
        siteId: 7,
        enableHeartBeatTimer: true
    })

    const store = await initializeStore()

   new Vue({
        store,
        i18n,
        render: (h) => h(App)
    }).$mount('#app')
}

initialize()
