import Vue from 'vue'
import Vuex from 'vuex'
import {ethers} from 'ethers'
import {EthereumProvider} from '@walletconnect/ethereum-provider'
import platformViewContract from '@/libs/blockchain_contracts/platformView'
import os2TicketSalesContract from '@/libs/blockchain_contracts/os2TicketSales'
import edificationContract from '@/libs/blockchain_contracts/edification'
import {captureException} from '@sentry/vue'

Vue.use(Vuex)

let store = undefined

const reconnectWallet = async() => {
  const lastConnection = localStorage.getItem('last_connection')
  if (!lastConnection) return

  if (lastConnection === 'window.ethereum') {
    if (window.ethereum) {
      try {
        const accounts = await window.ethereum.request({
          method: 'eth_accounts'
        })
        if (accounts.length > 0) {
          await store.dispatch('connectMetamask')
        }
      } catch (e) {
        captureException(e)
      }
    }
  } else if (lastConnection === 'wc') {
    if (!store.state.chainData.walletConnectProvider.session) return

    await store.dispatch('connectWalletConnect')
  }
}

const initializeStore = async() => {
  store = new Vuex.Store({
    state: {

      chainData: {
        readProvider: null,
        logsProvider: null,
        usedProvider: 0,
        chainId: '0x38',
        address: null,
        signer: null,
        provider: null,
        rawProvider: null,
        walletUnlockRequested: false,
        walletConnectProvider: null
      },
      displayData: {
        accountNFTs: [],
        selectedNFT: {
          level: -1
        },
        selectedNFTIndex: -1,
        ranks: []
      },

      ticketsInfo: {
        deadline: 0,
        ticketsLeft: [0, 0, 0],
        ticketsSold: [0, 0, 0],
        ticketsPrices: [ethers.BigNumber.from(0), ethers.BigNumber.from(0), ethers.BigNumber.from(0)]
      },

      ticketsData: [],

      accountData: {
        isCrypto: false,
        ticketInfo: {
          id: ethers.BigNumber.from(0),
          ticket: {}
        },
        ticketInfoAPI: {}
      },


      currentTimestamp: Number((new Date().getTime() / 1000).toFixed(0)),
      currentTimestampInterval: null,

      loadersActive: 0,
      isDropdownMenuOpen: false,
      showBackButton: true
    },
    getters: {
      selectedNFT(state) {
        return state.displayData.selectedNFT
      },
      selectedNFTLevel(state) {
        if (state.displayData.selectedNFT.level === -1) return 0
        return Number(state.displayData.selectedNFT.level)
      },
      stakedTokens(state) {
        return ethers.utils.formatUnits(
          state.displayData.selectedNFT.stakedTokens,
          18
        )
      },
      userSignedIn(state) {
        return state.chainData.address !== null
      },
      userHasNFT(state) {
        return state.displayData.accountNFTs.length > 0
      },
      appStatus(state) {
        return state.appStatus
      }
    },
    mutations: {
      START_LOADING(state) {
        state.loadersActive++
      },
      STOP_LOADING(state) {
        if (state.loadersActive > 0) state.loadersActive--
      },
      SET_APP_STATUS(state, status) {
        state.appStatus = status
      },
      SET_TICKET_API_INFO(state, info) {
        state.accountData.ticketInfoAPI = info
      }

    },
    actions: {
      async connectMetamask({state, dispatch, commit}) {
        commit('START_LOADING')
        try {
          state.chainData.walletUnlockRequested = true

          state.chainData.rawProvider = window.ethereum
          state.chainData.provider = new ethers.providers.Web3Provider(window.ethereum)
          state.chainData.usedProvider = 1
          const network = await state.chainData.provider.getNetwork()

          const addresses = await window.ethereum.request({
            method: 'eth_requestAccounts'
          })

          if (addresses.length > 0) {
            await dispatch(
              'handleChainChanged',
              ethers.utils.hexlify(network.chainId)
            )
            await dispatch('walletAddressChanged', addresses[0])
          }

          window.ethereum.on('chainChanged', async(e) => {
            if (state.chainData.usedProvider !== 1) return
            state.chainData.provider = new ethers.providers.Web3Provider(window.ethereum)
            await dispatch('handleChainChanged', e)
          })

          window.ethereum.on('accountsChanged', async(accounts) => {
            if (state.chainData.usedProvider !== 1) return
            await dispatch(
              'walletAddressChanged',
              accounts.length > 0 ? accounts[0] : null
            )
          })

          localStorage.setItem('last_connection', 'window.ethereum')
        } catch (e) {
          captureException(e)
        }

        state.chainData.walletUnlockRequested = false
        commit('STOP_LOADING')
      },
      async connectWalletConnect({state, dispatch, commit}) {
        commit('START_LOADING')

        try {
          await state.chainData.walletConnectProvider.enable()

          state.chainData.walletConnectProvider.on('accountsChanged', async(accounts) => {
            if (state.chainData.usedProvider !== 2) return
            await dispatch('walletAddressChanged', (accounts.length > 0) ? accounts[0] : null)
          })

          state.chainData.walletConnectProvider.on('chainChanged', async(chainId) => {
            if (state.chainData.usedProvider !== 2) return
            state.chainData.provider = new ethers.providers.Web3Provider(state.chainData.walletConnectProvider)
            await dispatch('handleChainChanged', ethers.utils.hexZeroPad(chainId, 1))
          })

          state.chainData.walletConnectProvider.on('disconnect', () => {
            localStorage.removeItem('last_connection')
            window.location.reload()
          })

          state.chainData.rawProvider = state.chainData.walletConnectProvider
          state.chainData.provider = new ethers.providers.Web3Provider(state.chainData.walletConnectProvider)
          state.chainData.usedProvider = 2
          const network = await state.chainData.provider.getNetwork()
          dispatch('handleChainChanged', ethers.utils.hexlify(network.chainId))
          dispatch('walletAddressChanged', state.chainData.walletConnectProvider.accounts[0])

          localStorage.setItem('last_connection', 'wc')

        } catch (e) {
          captureException(e)
        }

        commit('STOP_LOADING')
      },
      async switchChain({state, commit}) {

        if (state.chainData.usedProvider !== 2) return

        commit('START_LOADING')

        const bscData = {
          chainId: '0x38',
          chainName: 'Smart Chain',
          nativeCurrency: {
            name: 'BNB',
            symbol: 'BNB',
            decimals: 18
          },
          rpcUrls: ['https://bsc-dataseed.binance.org/'],
          blockExplorerUrls: ['https://bscscan.com']
        }

        try {
          await state.chainData.rawProvider.request({
            method: 'wallet_switchEthereumChain',
            params: [{chainId: '0x38'}]
          })
        } catch (switchError) {
          if (
            switchError.code === 4902 ||
            (switchError.toString() &&
              switchError
                .toString()
                .includes('wallet_addEthereumChain'))
          ) {
            try {
              await state.chainData.rawProvider.request({
                method: 'wallet_addEthereumChain',
                params: [bscData]
              })
            } catch (addError) {
              captureException(addError)
              console.log(addError)
            }
          } else {
            captureException(switchError)
            console.log(switchError)
          }
        }

        commit('STOP_LOADING')
      },
      async walletAddressChanged({state, dispatch, commit}, address) {
        commit('START_LOADING')
        if (address) {
          state.chainData.address = ethers.utils.getAddress(address)
        } else {
          state.chainData.address = address
        }

        try {
          if (address) {
            state.chainData.signer =
              state.chainData.provider.getSigner()
          }
        } catch (e) {
          captureException(e)
        }
        await dispatch('loadDataFromChain')
        commit('STOP_LOADING')
      },
      async handleChainChanged({state, commit, dispatch}, chainId) {
        commit('START_LOADING')
        try {
          state.chainData.chainId = chainId
          state.chainData.signer =
            state.chainData.provider.getSigner()

        } catch (e) {
          captureException(e)
        }

        await dispatch('loadDataFromChain')

        commit('STOP_LOADING')
      },
      async refreshNFTs({state, commit, dispatch}) {
        commit('START_LOADING')
        try {
          if (!state.chainData.address) {
            commit('STOP_LOADING')
            return
          }

          state.displayData.accountNFTs =
            await platformViewContract().getWalletData(
              state.chainData.address
            )
          if (state.displayData.accountNFTs.length === 0) {
            commit('STOP_LOADING')
            return
          }

          state.displayData.selectedNFT =
            state.displayData.accountNFTs[0]
          state.displayData.selectedNFTIndex = 0

          dispatch('loadAccountExtraData')
        } catch (e) {
          captureException(e)
        }

        commit('STOP_LOADING')
      },
      async loadTicketDataFromChain({state, commit}) {
        commit('START_LOADING')
        try {

          const ticketInfo = await os2TicketSalesContract().getBoughtTicketInfo(state.displayData.selectedNFT.ID)
          state.accountData.ticketInfo.id = ticketInfo.ticketId
          if (state.accountData.ticketInfo.id.eq(0)) {
            commit('STOP_LOADING')
            return
          }
          state.accountData.ticketInfo.ticket = ticketInfo
          commit('STOP_LOADING')
        } catch (e) {
          captureException(e)
          commit('STOP_LOADING')
        }

      },
      async refreshTicketsInfo({state, commit}) {
        commit('START_LOADING')
        try {
          const ticketsInfo = await os2TicketSalesContract().getTicketInfos()
          state.ticketsData = ticketsInfo
        } catch (e) {
          captureException(e)
        } finally {
          commit('STOP_LOADING')
        }
      },
      async refreshCurrentAccount({state, commit, dispatch}) {
        commit('START_LOADING')

        const newAccountList = state.displayData.accountNFTs.map(
          (nft) => {
            return {...nft}
          }
        )
        newAccountList[state.displayData.selectedNFTIndex] =
          await platformViewContract().getNFTData(
            state.displayData.accountNFTs[
              state.displayData.selectedNFTIndex
              ].ID
          )

        state.displayData.accountNFTs = newAccountList
        state.displayData.selectedNFT =
          state.displayData.accountNFTs[
            state.displayData.selectedNFTIndex
            ]
        dispatch('loadAccountExtraData')

        commit('STOP_LOADING')
      },
      async refreshRanks({state, commit}) {
        if (!state.displayData.selectedNFT.ID) {
          state.displayData.ranks = []
          return
        }

        commit('START_LOADING')
        state.displayData.ranks =
          await edificationContract().getAccountBadges(
            state.displayData.selectedNFT.ID
          )
        commit('STOP_LOADING')
      },
      async switchToAccount({state, dispatch}, newAccountIndex) {
        if (newAccountIndex >= state.displayData.accountNFTs.length) return

        state.displayData.selectedNFT =
          state.displayData.accountNFTs[newAccountIndex]
        state.displayData.selectedNFTIndex = newAccountIndex
        dispatch('loadAccountExtraData')
      },
      async loadAccountExtraData({state, dispatch}) {
        state.mainAccountId = state.displayData.selectedNFT.ID

        await dispatch('loadTicketDataFromChain')

        state.displayData.selectedNFT.userConfigValues.uintValues.forEach(
          (value) => {
            if (value[0] === 'is_crypto') {
              state.accountData.isCrypto =
                Number(value[1]) === Number(1)
            }
          }
        )

        if (!state.accountData.isCrypto) {
          dispatch('refreshRanks')
        }


      },
      async loadDataFromChain({dispatch, state, commit}) {
        commit('START_LOADING')
        state.loadingChainData = true

        try {
          const nftRefreshPromise = dispatch('refreshNFTs')
          await Promise.all([nftRefreshPromise])

        } catch (e) {
          captureException(e)
        }

        state.loadingChainData = false
        state.chainDataInitialized = true
        commit('STOP_LOADING')
      }
    }
  })

  store.state.chainData.walletConnectProvider = await EthereumProvider.init({
    projectId: 'cbf5da693d736fe1c74d2d6adab371d1',
    chains: ['56'],
    showQrModal: true,
    methods: ['eth_sendTransaction', 'personal_sign', 'eth_signTypedData', 'eth_signTypedData_v4', 'eth_sign'],
    events: ['chainChanged', 'accountsChanged', 'disconnect']
    /*metadata: {
        name: 'MetFi APP',
        url: 'https://app.metfi.io',
        icons: ['https://app.metfi.io/img/icons/icon-200x200.png']
    }*/
  })

  store.state.currentTimestampInterval = setInterval(() => {
    store.state.currentTimestamp = Number(
      (new Date().getTime() / 1000).toFixed(0)
    )
  }, 1000)

  store.state.chainData.readProvider = new ethers.providers.JsonRpcProvider(
    Vue.prototype.appConfig.readRPC
  )
  store.state.chainData.logsProvider = new ethers.providers.JsonRpcProvider(
    Vue.prototype.appConfig.logsRPC
  )
  store.state.chainData.provider = store.state.chainData.readProvider
  store.state.chainData.usedProvider = 0
  await reconnectWallet()
  await Promise.all([store.dispatch('refreshTicketsInfo')])

  return store
}
export {initializeStore, store as default}
