<template>
    <div id="connect-wallet" class="pillBase rounded"
         @mouseover="changeMenuStatus(true)"
         @mouseleave="changeMenuStatus(false)"
    >
        <div
            class="flex flex-row items-center min-w-[220px] justify-center px-2 py-1.5 rounded-lg"
            @click="changeMenuStatus(!$store.state.isDropdownMenuOpen)"
            ref="pillButton"
        >
            <template v-if="$store.state.chainData.address">
                <div
                    class="rounded w-full text-center loggedInPill h-full py-1"
                >
                    <span class="text-sm leading-4 font-bold">{{
                        getShortAddress()
                    }}</span>
                </div>
                <jazzicon
                    class="flex ml-3 mr-2"
                    :address="$store.state.chainData.address"
                    :diameter="25"
                />
            </template>
            <template v-else>
                <div class="ml-2 mr-4 py-1">
                    {{ $t('navigation.connect_your_wallet') }}
                </div>
                <jazzicon class="flex" :diameter="25" />
            </template>
        </div>

        <ul
            class="pillChildren px-2 cursor-pointer right-0 w-auto md:w-full mt-2"
            :class="{ 'menuOpen': $store.state.isDropdownMenuOpen }"
        >
            <dropdown-menu-item v-if="isLoggedIn">{{ getShortAddress() }}</dropdown-menu-item>

            <template v-if="!isLoggedIn">
                <dropdown-menu-item v-if="hasMetamask" @click="$store.dispatch('connectMetamask')">
                    <div class="flex items-center">
                        <img :src="require('@/assets/images/metamask_logo.svg')" />
                        <span class="text-base lg:text-lg ml-2">Metamask</span>
                    </div>
                </dropdown-menu-item>

                <dropdown-menu-item  @click="$store.dispatch('connectWalletConnect')">
                    <div class="flex items-center">
                        <img :src="require('@/assets/images/wallet_connect_logo.svg')"/>
                        <span class="text-base lg:text-lg ml-2">WalletConnect</span>
                    </div>
                </dropdown-menu-item>
            </template>

            <dropdown-menu-item @click="onBackToAppClicked">{{$t('navigation.back_to_app')}}</dropdown-menu-item>

            <dropdown-menu-item @click="disconnect" v-if="isLoggedIn">{{$t('navigation.logout')}}</dropdown-menu-item>

        </ul>
    </div>
</template>
<script>
    import Jazzicon from 'vue-jazzicon'
    import DropdownMenuItem from '@/components/navbar/DropdownMenuItem.vue'
    export default {
        components: {DropdownMenuItem, Jazzicon},
        data() {
            return {
                clickListener: null,
                hasMetamask: true
            }
        },
        computed: {

            selectedPosition() {
                return Number(this.$store.state.displayData.selectedNFT.ID)
            },
            isLoggedIn() {
                return this.$store.state.chainData.address
            },
            hasNFTs() {
                return this.isLoggedIn && this.$store.state.displayData.accountNFTs && this.$store.state.displayData.accountNFTs.length
            }
        },
        methods: {
            changeMenuStatus(isOpen) {
                this.$store.state.isDropdownMenuOpen = isOpen
            },
            getShortAddress() {
                if (this.isLoggedIn) return this.$shortAddress(this.$store.state.chainData.address)
            },
            disconnect() {
                if (this.$store.state.chainData.usedProvider === 2) {
                    localStorage.removeItem('last_connection')
                    Object.keys(localStorage)
                        .filter(x => x.startsWith('wc@2'))
                        .forEach(x => localStorage.removeItem(x))
                    this.$store.state.chainData.walletConnectProvider.disconnect()
                }
                localStorage.removeItem('last_connection')
                window.location.reload()
            },
            onBackToAppClicked() {
                window.open('https://app.metfi.io')
            }

        },
        created() {
            this.hasMetamask = !!window.ethereum
        }
    }
</script>
<style scoped>
.pillBase {
    font-weight: 500;
    background: #ba141a;
    white-space: nowrap;
    position: relative;
    cursor: pointer;
    color: white;
    width: 250px;
    max-height: 44px;
}

.pillChildren {
    max-height: 0;
    position: absolute;
    z-index: 10;
    transition: max-height 0.2s ease-out;
    overflow: hidden;
    background: #ba141a;
    border-radius: 5px 0px 0px 5px;
}

.menuOpen {
    max-height: 500px;
    transition: max-height 0.3s ease-in;
}

.pillChildren .pillMenuItem:first-child {
    margin-top: 0.5rem;
}
.pillChildren .pillMenuItem:last-child {
    margin-bottom: 0.5rem;
}
.pillChildren img {
  max-width: 35px;
}

</style>
