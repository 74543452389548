import Vue from 'vue'
import {ethers} from 'ethers'
const shortAddress = (val) => {
    return `${val.substring(0, 6)}...${val.substring(
        val.length - 5,
        val.length
    )}`
}
Vue.prototype.$shortAddress = shortAddress

const mediumAddress = (val) => {
    return `${val.substring(0, 16)}...${val.substring(
        val.length - 16,
        val.length
    )}`
}
Vue.prototype.$mediumAddress = mediumAddress

const formatDollar = (val, digitNum) => {
    if (isNaN(Number(val))) return Number(0).toLocaleString(undefined, {
            maximumFractionDigits: digitNum ? digitNum : 2
        })
    return Number(val).toLocaleString(undefined, {
        maximumFractionDigits: digitNum ? digitNum : 2
    })
}
Vue.prototype.$formatDollars = formatDollar

const formatDollarFromRaw = (val, digitNum) => {
  val = ethers.utils.formatEther(val)
  if (isNaN(Number(val))) return Number(0).toLocaleString(undefined, {
    maximumFractionDigits: digitNum ? digitNum : 2
  })
  return Number(val).toLocaleString(undefined, {
    maximumFractionDigits: digitNum ? digitNum : 2
  })
}
Vue.prototype.$formatDollarsFromRaw = formatDollarFromRaw

const generateRandomId = (prefix = '') => {
    return `${prefix ? `${prefix}-` : ''}-${(Math.random() * 1e6).toFixed(0)}`
}
const generateRandomString = (
    length = 5,
    characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
) => {
    let result = ''
    const charactersLength = characters.length
    for (let i = 0; i < length; i++) {
        result += characters.charAt(
            Math.floor(Math.random() * charactersLength)
        )
    }

    return result
}

export {
    shortAddress,
    formatDollar,
    mediumAddress,
    generateRandomId,
    generateRandomString
}
