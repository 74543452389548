<template>
    <div id="currency-dropdown-wrapper" class="w-full items-center flex flex-col justify-center">
        <div class="flex w-full justify-center mt-4" v-if="!isUpgrade">
            <div v-if="!mustBeGift" class="flex flex-row mb-2 items-center justify-center">
                <span class="font-bold text-xl mr-2">Gift Ticket{{isGift? '!' : '?' }}</span>
                <custom-switch v-model="isGift" name="gift-ticket"></custom-switch>
            </div>
            <div v-else class="flex flex-row mb-2 items-center justify-center">
                <span class="font-bold text-xl mr-2 text-center">Gift Ticket!</span>
            </div>
        </div>
        <div class="flex flex-wrap lg:flex-nowrap gap-4 mt-2 mb-4"  v-if="isGift">
            <vue-select class='gift-option-dropdown whitespace-nowrap w-full flex items-center min-h-[53px] text-[1.2rem] lg:max-w-[150px]'
                        :filterable="false" :serchable="false" label="text"  :reduce="o => o.value"
                        :clearable="false" :options="giftOptions" v-model="giftOption">
                <template #option="{text}">
                    <span class="font-bold align-middle">
                        {{ text }}
                    </span>
                </template>
                <template #selected-option-container="{option}">
                    <div class="p-2 selected-currency font-bold align-middle leading-4">
                        {{ option.text }}
                    </div>
                </template>
            </vue-select>
            <custom-input class="w-full" :placeholder="`Enter ${giftOptions.find(x => x.value === giftOption).text}`" v-model="giftValue"
            />
        </div>
        <div class="flex flex-col items-center max-w-4xl">
            <div class="flex flex-row gap-4">

                <custom-button class="p-1 sm:p-2 md:text-xl currency-converted-btn cursor-default" block>

                    <div class="flex h-full">

                        <div v-if="isOtherCurrency" class="flex items-center w-full">
                            <span class="px-2 cursor-pointer" @click="onClearOtherAddressClicked">
                                <x-icon/>
                            </span>
                            <custom-input  class="w-full mr-2" @input="addressChanged" :value="customCurrencyAddress" placeholder="0x..." red/>
                        </div>
                        <vue-select v-else
                                    appendToBody :calculatePosition="calculatePosition"
                                    class="currency-dropdown whitespace-nowrap mr-2 w-full flex items-center" label="name" :options="currenciesArray"
                                    :filterable="false" :clearable="false" :searchable="false" v-model="selectedCurrency"
                                    @input="recalculateCurrencyData" >
                            <template #option="{name, icon}">
                                <div class="p-1 w-fit whitespace-nowrap mr-2">
                                    <img class="vue-select-icon mr-2 inline" :src="getIcon(icon)" :alt="name"/>
                                    <span class="font-bold align-middle sm:text-lg">
                                        {{ name }}
                                    </span>
                                </div>
                            </template>
                            <template #selected-option-container="{option}">
                                <div class="p-2 w-fit whitespace-nowrap selected-currency flex items-center">
                                    <img class="vue-select-icon mr-2" :src="getIcon(option.icon)" :alt="option.name"/>
                                    <span class="font-bold align-middle leading-4 margin-top-2px text-sm sm:text-lg">
                                        {{ option.name }}
                                    </span>
                                </div>
                            </template>
                        </vue-select>


                        <div class="vertical-line"></div>

                        <div class="pl-2 text-center w-full flex items-center justify-center right-side-button font-bold">
                            <HashLoader v-if="isButtonLoaderActive" sizeUnit="px" :size="30" color="#ffffff"></HashLoader>
                            <template v-else>

                                <div class="h-full flex text-sm sm:text-lg">
                                    <div class="flex text-center items-center" v-if="dataStatus === 0">{{$t('currency_converter.invalid_address')}}</div>
                                    <div class="flex text-center items-center" v-else-if="dataStatus === 1">{{$t('currency_converter.insufficient_balance')}}</div>
                                    <div class="flex text-center items-center cursor-pointer" v-else-if="dataStatus === 2" @click="onApproveAllowanceClicked">{{$t('currency_converter.approve_currency')}}</div>
                                    <div class="flex text-center items-center" v-else-if="dataStatus === 3">{{$t('currency_converter.invalid_address')}}</div>
                                    <div class="flex text-center items-center" v-else-if="dataStatus === 4">{{$t('currency_converter.no_exchange_path_available')}}</div>
                                    <div class="flex text-center items-center cursor-pointer" v-else-if="dataStatus === 5" @click="onFinalizeClicked">
                                        <template v-if="isGift">Gift Now</template>
                                        <template v-else-if="isUpgrade">Upgrade Now</template>
                                        <template v-else>Buy Now</template>
                                    </div>
                                </div>
                            </template>

                        </div>
                    </div>

                </custom-button>
            </div>

            <div class="mt-2 font-bold text-lg" v-if="dataStatus === 5 || dataStatus === 2 || dataStatus === 1">
                {{$t('currency_converter.you_will_pay')}} {{ inputAmountFormatted }} {{ selectedCurrencyName }}

            </div>

        </div>

    </div>
</template>
<script>
    import VueSelect from 'vue-select'
    import CustomInput from '@/components/CustomInput.vue'
    import {ethers} from 'ethers'
    import erc20ABI from '@/libs/blockchain_contracts/ABI/ERC20.sol/ERC20.json'
    import {HashLoader} from '@saeris/vue-spinners'

    import CustomButton from '@/components/CustomButton.vue'
    import os2TicketSalesContract from '@/libs/blockchain_contracts/os2TicketSales'
    import chainAddresses from '@/libs/blockchain_contracts/chainAddresses'
    import {XIcon} from 'vue-tabler-icons'
    import CustomSwitch from '@/components/CustomSwitch.vue'
    import {captureException} from '@sentry/vue'
    import priceConversionCalculatorContract from '@/libs/blockchain_contracts/priceConversionCalculator'

    export default {
        components: {
            CustomSwitch,
            CustomButton,
            HashLoader,
            XIcon,
            CustomInput,
            VueSelect
        },
        props: {
            slippage: {
                type: Number
            },
            ticketType: {
                type: Number,
                required: true
            },
            mustBeGift: {
                type: Boolean,
                default: false
            },
            isUpgrade: {
                type: Boolean,
                default: false
            },
            isEarlyBird: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                selectedCurrency: null,
                selectedCurrencyName: '',
                selectedCurrencyDecimals: 18,
                currenciesArray: [],
                customCurrencyAddress: '',
                isButtonLoaderActive: 0,
                dataStatus: 0,
                inputAmount: ethers.BigNumber.from(0),
                targetContractAddress: chainAddresses.os_ii_ticket_sales,
                isGift: this.mustBeGift,
                giftValue: '',
                giftOptions: [
                    { text: 'NFT ID', value: this.$GiftOption.NFT_ID },
                    { text: 'Username', value: this.$GiftOption.USERNAME },
                    { text: 'Address', value: this.$GiftOption.ADDRESS }
                ],
                giftOption: this.$GiftOption.NFT_ID
            }
        },
        methods: {
            calculatePosition(dropdownList, yellowButton, {width, left}) {
                const rect = dropdownList.getBoundingClientRect()
                const yellowButtonRect = yellowButton.$el.getBoundingClientRect()

                if (!this.isInViewport(dropdownList)) { // will be positioned on top of button
                    // height of yellow button - height of dropdown
                    dropdownList.style.top = `${yellowButtonRect.y - rect.height - 20}px`
                } else {
                    // vertical position of yellow button + height of yellow button + some margin
                    dropdownList.style.top = `${yellowButtonRect.y + yellowButtonRect.height + 10}px`
                }

                dropdownList.style.left = left
                dropdownList.style.zIndex = 99999
                dropdownList.style.width = width
            },
            isInViewport(element) {
                const rect = element.getBoundingClientRect()
                if (!element) return true

                return (
                    rect.left >= 0 &&
                    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
                )
            },
            onClearOtherAddressClicked() {
                this.selectedCurrency = this.currenciesArray.find(c => c.name === 'USDT')
                this.recalculateCurrencyData()
            },
            async onFinalizeClicked() {

                if (this.isGift) {
                    if (this.giftValue === '') {
                        this.$printWarning('Invalid NFT ID')
                        return
                    }
                }

                const finalAmount = this.inputAmount
                    .mul(
                        ethers.BigNumber
                            .from(
                                (this.slippage * 1_000_000).toFixed(0)
                            ))
                    .div(ethers.BigNumber.from(1_000_000))

                this.$emit('finalize', {
                    amount: finalAmount,
                    address: this.inputCurrencyAddress,
                    isBNB: this.selectedCurrency.name === 'BNB',
                    ticketType: this.ticketType,
                    isGift: this.isGift,
                    giftValue: this.giftValue,
                    giftType: this.giftOption,
                    isUpgrade: this.isUpgrade
                })
            },
            getIcon(icon) {
                if (!icon) return ''
                return require(`@/assets/images/currencies/${icon}`)
            },
            async recalculateCurrencyData() {

                this.isButtonLoaderActive++
                this.dataStatus = 0

                /*
                Statuses
                0 - Calculating
                1 - Insufficient balance
                2 - Insufficient allowance
                3 - Invalid address
                4 - No currency/USDT pool
                5 - Status OK
                 */

                if (this.isUpgrade) {
                    if (!this.$store.state.accountData.ticketInfo.ticket || !this.$store.state.accountData.ticketInfo.ticket.paidPrice) {
                        this.$printError('There was an error calculating price. Please refresh your website!')
                        this.dataStatus = 4
                        return
                    }
                    const paidForSilver = ethers.utils.formatUnits(this.$store.state.accountData.ticketInfo.ticket.paidPrice, 18)

                    let priceToPay = null
                    if (this.isEarlyBird) priceToPay =  (700 - paidForSilver).toFixed(0)
                    else priceToPay = (1000 - paidForSilver).toFixed(0)

                    if (priceToPay < 0) {
                        this.$printError('There was an error calculating price. Please refresh your website!')
                        this.dataStatus = 4
                        return
                    }

                    const paidPriceInEthersUnit = ethers.utils.parseUnits(priceToPay, 18)

                    try {
                        this.inputAmount = await priceConversionCalculatorContract().calculatePriceForConvertToExactAmount(this.inputCurrencyAddress, paidPriceInEthersUnit, chainAddresses.usdt, 5e6)
                    } catch (e) {
                        captureException(e)
                        this.dataStatus = 4
                        return
                    }
                } else {
                    try {
                        this.inputAmount = await os2TicketSalesContract().calculatePrice(this.ticketType, this.inputCurrencyAddress)
                    } catch (e) {
                        captureException(e)
                        this.dataStatus = 4
                        return
                    }
                }

                //--------- Set input token contract
                const inputTokenContract = new ethers.Contract(this.inputCurrencyAddress, erc20ABI, this.$store.state.chainData.readProvider)
                //----------

                //--------- Set currency name (and get it from chain in case of external currency)
                this.selectedCurrencyName = this.selectedCurrency.name
                if (this.selectedCurrency.address === 'OTHR') {
                    this.selectedCurrencyName = await inputTokenContract.symbol()
                }
                //----------

                //--------- Get and check balance
                let balance = ethers.BigNumber.from(0)
                if (this.selectedCurrency.name === 'BNB') {
                    balance = await this.$store.state.chainData.readProvider.getBalance(this.$store.state.chainData.address)
                } else {
                    balance = await inputTokenContract.balanceOf(this.$store.state.chainData.address)
                }

                if (balance.lt(this.inputAmount)) {
                    this.dataStatus = 1
                    this.isButtonLoaderActive--
                    return
                }

                //----------

                //--------- Get allowance
                let allowance = ethers.utils.parseUnits('1000000000')
                if (this.selectedCurrency.name !== 'BNB') {
                    allowance = await inputTokenContract.allowance(this.$store.state.chainData.address, this.targetContractAddress)
                    this.selectedCurrencyDecimals = Number(await inputTokenContract.decimals())
                }

                if (allowance.lt(this.inputAmount)) {
                    this.dataStatus = 2
                    this.isButtonLoaderActive--
                    return
                }
                //----------


                this.dataStatus = 5

                this.isButtonLoaderActive--
            },
            addressChanged(val) {
                this.customCurrencyAddress = val
                this.recalculateCurrencyData()
            },
            async onApproveAllowanceClicked() {

                try {
                    this.isButtonLoaderActive++
                    const inputTokenContract = new ethers.Contract(this.inputCurrencyAddress, erc20ABI, this.$store.state.chainData.readProvider)
                    const tx = await inputTokenContract.connect(this.$store.state.chainData.signer).approve(this.targetContractAddress, ethers.utils.parseUnits('1000000000', 18))
                    await tx.wait()
                } catch (e) {
                    captureException(e)
                } finally {
                    await this.recalculateCurrencyData()
                    this.isButtonLoaderActive--
                }
            }
        },
        computed: {
            isOtherCurrency() {
                if (!this.selectedCurrency) return false
                return this.selectedCurrency.address === 'OTHR'
            },
            inputCurrencyAddress() {
                let startingAddress = this.selectedCurrency.address
                if (this.selectedCurrency.address === 'OTHR') {
                    startingAddress = this.customCurrencyAddress
                }

                return startingAddress
            },
            inputAmountFormatted() {
                return this.$formatDollars(ethers.utils.formatUnits(this.inputAmount), 4)
            }
        },
        watch: {
            amount() {
                this.recalculateCurrencyData()
            }
        },
        created() {

            this.currenciesArray = []

            for (const k in this.$currencies) {
                this.currenciesArray.push({
                    name: k,
                    icon: this.$currencies[k].icon,
                    address: this.$currencies[k].address,
                    decimals: this.$currencies[k].decimals
                })
            }

            /*this.currenciesArray.push({
                name: this.$t('currency_converter.other'),
                icon: '',
                address: 'OTHR'
            })*/

            this.currenciesArray.splice(1, 0, {
                name: 'BNB',
                icon: 'bnb.svg',
                address: '0x0000000000000000000000000000000000000000'
            })

            this.selectedCurrency = this.currenciesArray.find(c => c.name === 'USDT')
        },
        mounted() {
            this.recalculateCurrencyData()
        }
    }
</script>
<style>
.style-chooser.vs__dropdown-menu {
  background: #dfe5fb;
  border: none;
  color: #394066;
  text-transform: lowercase;
  font-variant: small-caps;
}
#currency-dropdown-wrapper .vs__search {
  /* Hiding search input */
  position: absolute;
}
#currency-dropdown-wrapper .vs__actions {
  padding-top: 0 !important;
}
#currency-dropdown-wrapper .vs__open-indicator {
  margin-top: 0 !important;
}
#currency-dropdown-wrapper .v-select {
  display: flex;
  align-items: center;
}
#currency-dropdown-wrapper .vs__dropdown-toggle {
  border: none !important;
}
#currency-dropdown-wrapper .gift-option-dropdown .vs__dropdown-toggle {
  width: 100% !important;
  margin-right: 8px;
}
</style>
<style scoped lang="scss">
@import 'vue-select/src/scss/vue-select.scss';
.vue-select-icon {
  max-width: 25px;
}
.currency-dropdown {
  width: 100%;
  max-width: 160px;
}
.selected-currency {
  width: 100%;
  min-width: 100px;
}
.v-select {
  background: rgba(0, 0, 0, 0) !important;
  border: none !important;
}

::v-deep .vs__dropdown-toggle {
    padding: 3px 3px 3px 6px;
}

.vertical-line {
  border: 1px solid white;
  background-color: white;
}
.right-side-button {
  min-width: 80px;
}

.textRed {
  color: #FB3640;
}

.textGreen {
  color: #2ecc71;
}

.address-input-wrapper {
  padding: 10px;
  background-color: #FB3640;
  border-radius: 1rem;
}
.remove-bottom-radius {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.margin-top-2px {
  margin-top: 2px;
}
.currency-converted-btn:hover .vertical-line {
  border: 1px solid #fff;
  background-color: #fff;
}
.gift-option-dropdown {
  background-color: #ba141a !important;
  border-radius: 8px;
  color: white;
}

</style>
