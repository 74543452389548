<template>
    <div id="app">
        <div
            class="aurora fixed"
            style="height: 100vh; width: 100vw; z-index: -1"
        />
        <div
            class="loaderStyle flex flex-wrap items-center justify-center"
            v-if="$store.state.loadersActive > 0"
        >
            <HashLoader sizeUnit="px" :size="100" color="#60DBC1" />
        </div>

        <div class="container mx-auto p-4">
            <main-view />
        </div>
        <div class="w-full">
            <FooterComponent/>
        </div>
    </div>
</template>
<script>
    import { HashLoader } from '@saeris/vue-spinners'
    import MainView from '@/views/MainView.vue'
    import FooterComponent from '@/components/FooterComponent.vue'

    export default {
        components: {
            FooterComponent,
            MainView,
            HashLoader
        },
        data() {
            return {
            }
        },
        methods: {

        },
        beforeDestroy() {
            if (this.$store.state.currentTimestampInterval) clearInterval(this.$store.state.currentTimestampInterval)
        },
        mounted() {

        }
    }
</script>
<style lang="scss" scoped>
.appContainer {
    width: 100%;
    max-width: 1600px;
}

.loaderStyle {
    position: fixed;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: 20;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(1px);
}
</style>
