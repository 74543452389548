import Vue from 'vue'
const giftOption = {
    NFT_ID: 1,
    USERNAME: 2,
    ADDRESS: 3
}

Vue.prototype.$GiftOption = giftOption

export default giftOption
