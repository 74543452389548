<template>
    <button :class="classes" @click="$emit('click')" :disabled="disabled">
        <slot />
    </button>
</template>
<script>
    export default {
        props: {
            disabled: Boolean,
            block: Boolean,
            variant: {
                type: String,
                default: 'default',
                validator(value) {
                    return ['default', 'green'].includes(value)
                }
            }
        },
        computed: {
            classes() {
                const classes = ['py-4 px-4 text-xl md:px-4']

                if (this.block) classes.push('w-full')
                else classes.push('w-64')

                if (this.variant === 'default') classes.push('bg-default')
                else if (this.variant === 'green') classes.push('bg-green')

                if (this.disabled) classes.push('disabled')

                return classes
            }
        }
    }
</script>
<style scoped>
button {
    border-radius: 5px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    transition-duration: 200ms;

    /*No text copy*/
    user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

.bg-default {
    background-color: #ba141a;
    border: 1px solid #ba141a;;
    color: white;
}
.bg-default:hover {
    background-color: #700e0f;
    border: 1px solid #700e0f;
}
.bg-default.disabled {
    background-color: #ffdda0;
    border: 1px solid #faa819;
    color: black;
}
.disabled {
    cursor: not-allowed;
}
.bg-green {
    background: #669c97;
    border: 1px solid #669c97;
    color: white;
}
.bg-green:hover {
    background-color: #ebf2f1;
    border: 1px solid #de9e21;
    color: #669c97;
}
.bg-green.disabled {
    background: #ebf2f1;
    border: 1px solid #669c97;
    color: #337d77;
}
</style>
