<template>
    <div class="pb-8" id="top-10">
        <h2 class="text-2xl lg:text-4xl font-bold leading-6 lg:leading-10 text-red text-left pt-4">METFI Top 10</h2>
        <p class="text-base lg:text-xl leading-5 pt-6">USDT sales volume from your personal NFT referrals plus upgrading your own NFT
            will be calculated and the 10 MetFiers with the highest USDT sales volume until May 18th, win the following amounts of METFI tokens.</p>

        <div class="pt-8" >

            <table class="top-10-table text-base lg:text-xl" v-if="loaded">
                <tr class="text-left">
                    <th class="pl-2">#</th>
                    <th class="pl-6">Amount</th>
                    <th class="pl-6">User</th>
                </tr>
                <tr v-for="row of rows" v-bind:key="`top-10-${row.place}`">
                    <td class="font-bold py-1 pr-3 pl-2">{{ row.place }}</td>
                    <td class="font-bold py-1 pl-6 pr-8 lg:pr-20">{{ row.amount }} METFI</td>
                    <td class="font-bold py-1 pl-6 pr-8 lg:pr-20" >#{{ row.nft_id }} <small class="font-normal">({{$nftLevels()[row.level].name}})</small></td>
                </tr>
            </table>
            <div v-else class="text-base lg:text-xl font-bold">Loading...</div>
        </div>
        <div class="pt-8">
            <p class="text-base"><span class="text-red">*</span> Only NFT 5 Dolphins and above will be calculated.</p>
            <p class="text-base pt-2"><span class="text-red">*</span> Leaderboard is updated approximately once every 5 minutes.</p>
        </div>

    </div>
</template>
<script>

    import axios from '@/libs/axios'
    import {captureException} from '@sentry/vue'

    export default {
        components: {},
        data() {
            return {
                rows: [
                    {place: 1, amount: '30,000', nft_id: 0, level: 0},
                    {place: 2, amount: '15,000', nft_id: 0, level: 0},
                    {place: 3, amount: '10,000', nft_id: 0, level: 0},
                    {place: 4, amount: '9,000', nft_id: 0, level: 0},
                    {place: 5, amount: '8,000', nft_id: 0, level: 0},
                    {place: 6, amount: '7,000', nft_id: 0, level: 0},
                    {place: 7, amount: '6,000', nft_id: 0, level: 0},
                    {place: 8, amount: '5,000', nft_id: 0, level: 0},
                    {place: 9, amount: '5,000', nft_id: 0, level: 0},
                    {place: 10, amount: '5,000', nft_id: 0, level: 0}
                ],
                // [{"nft_id":27872,"level":8}]
                top10: [],
                loaded: false
            }
        },
        methods: {
            async loadData() {
                try {
                    const res = await axios.get('/api/leaderboard/v1/top10')
                    const top10 = res.data
                    if (top10.length !== 10) {
                        return
                    }
                    for (let i = 0; i < this.rows.length; i++) {
                        this.rows[i].nft_id = top10[i].nft_id
                        this.rows[i].level = top10[i].level
                    }
                    this.loaded = true
                } catch (e) {
                    captureException(e)
                } finally {
                    setTimeout(async() => { await this.loadData() }, 5 * 1000)
                }
            }
        },
        async mounted() {
            await this.loadData()
        }
    }

</script>
<style scoped>
.top-10-table tr {
  border-bottom: 2px dotted #ba141a;
}

.top-10-table td:not(:last-child) {
  border-right: 2px dotted #ba141a;
}
</style>
