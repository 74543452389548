<template>

    <card :border="selected ? 'red-full' : 'red-dotted'" body-class="h-full flex flex-col"
          :card-class="ticketsLeft && !selected ? 'h-full p-3 cursor-pointer selectableCard' : 'h-full p-3'" background="gold-gradient"
          @click.native="onPassSelected"
          :animate-background="selected">

        <div class="flex justify-between">
            <div class="pr-4">
                <div class="relative">
                    <img src="@/assets/images/red-early-bird.svg" class="absolute text-red -top-2 -left-2" v-if="displayEarlyBirdSpecial"/>
                    <ticket-icon stroke-width="1" size="35" class="leading-8 text-red" />
                </div>
                <div class="leading-8 text-3xl">Gold Pass</div>
                <div class="leading-8 font-bold text-3xl text-red">{{price}}  USDT</div>
                <div class="leading-5 font-bold" v-if="displayEarlyBirdSpecial">Early-Bird Pricing till 31st Jan, after that <span class="text-red">1,000 USDT</span></div>
            </div>
        </div>

        <div class="w-11/12 mx-auto">
            <div class="dotted-red-line pt-4"></div>
        </div>
        <div class="flex justify-between flex-col pt-2">
            <div class="leading-8 font-bold text-3xl">4 Days</div>
        </div>

        <div class="pt-2">
            <ul class="list-none">
                <li class="leading-7"><span class="font-bold">Day 1</span> - Registration with fun meet and greet</li>
                <li class="leading-7"><span class="font-bold">Day 2</span> - Main Event Gold Seating</li>
                <li class="leading-7"><span class="font-bold">Day 3</span> - Gala Party (Evening)</li>
                <li class="leading-7"><span class="font-bold">Day 4</span> - Millionaire Mentorship Training</li>
            </ul>
        </div>

        <div class="mt-6">
            <span class="leading-5 text-lg">Gold Ticket holders also receive special gifts plus limited and exclusive Ocean Summit II Merchandise.</span>
        </div>

        <div class="mt-4 flex justify-center items-end flex-1">
            <template v-if="selected">
                <switch-network-check v-if="ticketsLeft">
                    <currency-converter :ticket-type="1"
                                        :slippage="1.05"
                                        :must-be-gift="mustBeGift"
                                        :is-upgrade="isUpgrade"
                                        :is-early-bird="displayEarlyBirdSpecial"
                                        @finalize="args => $emit('on-buy-now-clicked', args)"/>
                </switch-network-check>
                <div v-else>
                    <custom-button disabled>Sold out</custom-button>
                </div>
            </template>
            <template v-else>
                <custom-button v-if="ticketsLeft" @click="$emit('on-pass-selected', 'gold')">Select</custom-button>
                <div v-else>
                    <custom-button disabled>Sold out</custom-button>
                </div>
            </template>
        </div>
    </card>
</template>

<script>
    import Card from '@/components/Card.vue'
    import CustomButton from '@/components/CustomButton.vue'
    import {TicketIcon} from 'vue-tabler-icons'
    import SwitchNetworkCheck from '@/components/SwitchNetworkCheck.vue'
    import CurrencyConverter from '@/components/CurrencyConverter.vue'
    import {ethers} from 'ethers'

    export default {
        props: {
            selected: {
                type: Boolean,
                default: false
            },
            mustBeGift: {
                type: Boolean,
                default: false
            },
            isUpgrade: {
                type: Boolean,
                default: false
            }
        },
        methods: {
            onPassSelected() {
                if (this.ticketsLeft && !this.selected) this.$emit('on-pass-selected', 'gold')
            }
        },
        computed: {
            displayEarlyBirdSpecial() {
                return this.$dayjs().isBefore(this.$dayjs(new Date(1706745599000)))
            },
            ticketsLeft() {
                if (!this.$store.state.ticketsData ||  !this.$store.state.ticketsData) return false
                return Number(this.$store.state.ticketsData[1].ticketsLeft) > (0)
            },
            price() {
                let paidPrice = 0
                if (this.isUpgrade) {
                    if (!this.$store.state.accountData.ticketInfo.ticket || !this.$store.state.accountData.ticketInfo.ticket.paidPrice) paidPrice = 0
                    paidPrice = ethers.utils.formatUnits(this.$store.state.accountData.ticketInfo.ticket.paidPrice, 18)
                }

                if (this.displayEarlyBirdSpecial) return (700 - paidPrice).toFixed(0)
                else return (1000 - paidPrice).toFixed(0)
            }
        },
        components: {Card, CustomButton, TicketIcon, SwitchNetworkCheck, CurrencyConverter}
    }
</script>
