<template>
    <div class="card" :class="getCardClasses()" :style="getStyles()">
        <div class="flex h-full">
            <div class="w-full flex flex-col">
                <div
                    class="title text-left text-xl md:text-4xl flex items-center"
                    :ref="cardName"
                >
                    <span class="break-words w-full">
                        <slot name="title"></slot>
                    </span>
                </div>
                <div
                    class="py-2"
                    :class="hrClass"
                    v-if="showHorizontalLine"
                    v-once
                >
                    <hr class="hr" />
                </div>

                <div :class="getBodyClasses()">
                    <slot></slot>
                </div>
            </div>
            <div v-if="hasRightSideSlot">
                <slot name="right-side"></slot>
            </div>
        </div>
    </div>
</template>
<script>
    import { generateRandomId } from '@/libs/helpers'

    export default {
        data() {
            return {
                negativeMarginForRightSide: '',
                cardName: ''
            }
        },
        props: {
            roundedClass: {
                type: String,
                default: 'rounded-lg'
            },
            border: {
                type: String,
                default: 'default',
                validator(value) {
                    return [
                        'default',
                        'danger',
                        'success',
                        'warning',
                        'white',
                        'transparent',
                        'pink',
                        'light-green',
                        'red-dotted',
                        'red-full'
                    ].includes(value)
                }
            },
            background: {
                type: String,
                default: 'default',
                validator(value) {
                    return ['white', 'default', 'light-green', 'blue', 'brown', 'silver-gradient', 'gold-gradient', 'platinum-gradient'].includes(value)
                }
            },
            shadow: {
                type: Boolean,
                default: false
            },
            showHorizontalLine: {
                type: Boolean,
                default: false
            },
            bgImage: {
                type: String,
                default: ''
            },
            name: {
                type: String,
                default: ''
            },
            bodyClass: {
                type: String,
                default: ''
            },
            cardClass: {
                type: String,
                default: 'p-3 lg:p-5'
            },
            hrClass: {
                type: String,
                default: ''
            },
            animateBackground: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            hasRightSideSlot() {
                return !!this.$slots['right-side']
            }
        },
        methods: {
            getBorderClasses() {
                switch (this.border) {
                case 'danger':
                    return 'border-danger'
                case 'success':
                    return 'border-success'
                case 'warning':
                    return 'border-warning'
                case 'white':
                    return 'border-white'
                case 'transparent':
                    return 'border-transparent'
                case 'pink':
                    return 'border-pink'
                case 'light-green':
                    return 'border-light-green'
                case 'red-dotted':
                    return 'border-red-dotted'
                case 'red-full':
                    return 'border-red-full'
                }
            },
            getBackgroundClasses() {
                switch (this.background) {
                case 'white':
                    return 'bg-white'
                case 'light-green':
                    return 'bg-light-green'
                case 'blue':
                    return 'bg-blue'
                case 'brown':
                    return 'bg-brown'
                case 'silver-gradient':
                    return 'bg-silver-gradient'
                case 'gold-gradient':
                    return 'bg-gold-gradient'
                case 'platinum-gradient':
                    return 'bg-platinum-gradient'
                }

                if (this.bgImage) return 'bg-image'
            },
            getCardClasses() {
                const classes = [
                    this.roundedClass,
                    this.getBorderClasses(),
                    this.getBackgroundClasses(),
                    this.cardClass
                ]

                if (this.shadow) classes.push('card-shadow')
                if (this.bgImage) classes.push('bg-image')
                if (this.animateBackground) classes.push('animate-gradient')

                return classes
            },
            getStyles() {
                const styles = {}
                if (this.bgImage) {
                    const img = require(`@/assets/images/${this.bgImage}`)
                    styles['background-image'] = `url(${img})`
                }

                return styles
            },
            getBodyClasses() {
                const classes = []
                if (this.bodyClass) classes.push(this.bodyClass)
                else classes.push('mt-4 flex flex-col')
                return classes
            }
        },
        created() {
            if (this.name) this.cardName = this.name
            else this.cardName = generateRandomId('card')
        }
    }
</script>
<style scoped>
.card {
    background: rgba(255, 255, 255, 0.3);
    border: 2px solid #669c97;
    backdrop-filter: blur(4.2px);
}
.card-image-wrapper {
    width: 83px;
}
.card-image {
    width: 100%;
    filter: drop-shadow(10px 4px 11px rgba(0, 0, 0, 0.25));
}

.border-white {
    border: 2px solid #ffffff;
}
.border-danger {
    border: 2px solid #ed1a39;
}
.border-success {
    border: 2px solid #3dbdb0;
}
.border-warning {
    border: 2px solid #d78128;
}
.border-transparent {
    border: none;
}
.border-pink {
    border: 1px solid rgba(237, 26, 57, 0.38);
}
.border-light-green {
    border: 1px solid rgba(61, 189, 176, 0.34);
}
.border-red-dotted {
  border: 2px dotted #ba141a;
}
.border-red-full {
  border: 2px solid #ba141a;
}
.bg-white {
    background: linear-gradient(
        27.81deg,
        #ffffff 38.2%,
        rgba(255, 255, 255, 0.3) 115.34%
    );
}
.bg-light-green {
    background: linear-gradient(
        27.81deg,
        rgba(61, 189, 176, 0.1311) 38.2%,
        rgba(255, 255, 255, 0.207) 106.1%
    );
}
.bg-blue {
  background: linear-gradient(120.57deg, rgba(61, 189, 176, 0.34) 6.53%, rgba(81, 197, 224, 0.34) 84.57%);
}
.card-shadow {
    box-shadow: 10px 11px 21px rgba(0, 0, 0, 0.1);
}
.bg-image {
    /*background-origin: border-box;*/
    background-repeat: no-repeat;
    /*background-position: center;*/
    background-size: cover;
}
.bg-brown {
 background-color: #fff7eb;
}

.bg-silver-gradient {
  background: #E9E9E9 linear-gradient(
      to bottom left,
      rgba(232, 220, 202, 1) 30%,
      rgba(254, 238, 216, 0.1) 100%
  );
}
.bg-gold-gradient {
  background: linear-gradient(
      to bottom right,
      rgba(255, 242, 203, 0.3) 0%,
      rgba(228, 199, 141, 1) 100%
  );
}
.bg-platinum-gradient {
  background: linear-gradient(
      0.7turn,
      rgba(255, 255, 255, 1) 30%,
      rgba(196, 191, 165, 1) 100%
  );
}
.animate-gradient {
  background-size: 300% 300%;
  animation: gradient 5s ease infinite;
}
@keyframes gradient {
  0% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}
</style>
