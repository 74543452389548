<template>
    <span
        class="pillBase flex justify-around items-center px-1 sm:px-4 rounded w-fit min-w-[2.75rem] h-full"
    >
        <img
            class="go-back-icon"
            :src="require('@/assets/images/chevron-left.svg')"
        />
        <span class="hidden sm:block">Back</span>
    </span>
</template>
<script>
    export default {}
</script>
<style scoped>
.go-back-icon {
    min-width: 24px;
    min-height: 24px;
}
.pillBase {
    font-weight: 500;
    background: #ba141a;
    white-space: nowrap;
    position: relative;
    cursor: pointer;
    color: white;
    /*width: auto;*/
    max-height: 44px;
}
</style>
