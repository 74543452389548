<template>
    <div>
        <CustomButton
            key="switchNetwork-button"
            v-if="$store.state.chainData.chainId !== '0x38'"
            @click="$store.dispatch('switchChain')"
        >
            {{ $t('wrong_network.switch_to_bsc') }}</CustomButton
        >
        <slot v-else />
    </div>
</template>
<script>
    import CustomButton from '@/components/CustomButton'
    export default {
        components: { CustomButton }
    }
</script>
