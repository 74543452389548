import Vue from 'vue'
import i18n from '@/libs/i18n'

const nftLevels = () => {
    return [
        {
            color: '#2DBF3B',
            price: 100,
            apy: 100,
            name: i18n.t('nfts.shrimp.name'),
            additionalName: i18n.t('nfts.shrimp.sub'),
            image: require('@/assets/images/nfts/level1.jpg')
        },
        {
            color: '#60EFFC',
            price: 200,
            apy: 200,
            name: i18n.t('nfts.crab.name'),
            additionalName: i18n.t('nfts.crab.sub'),
            image: require('@/assets/images/nfts/level2.jpg')
        },
        {
            color: '#FF90E4',
            price: 400,
            apy: 300,
            name: i18n.t('nfts.octopus.name'),
            additionalName: i18n.t('nfts.octopus.sub'),
            image: require('@/assets/images/nfts/level3.jpg')
        },
        {
            color: '#3B2DBF',
            price: 800,
            apy: 400,
            name: i18n.t('nfts.fish.name'),
            additionalName: i18n.t('nfts.fish.sub'),
            image: require('@/assets/images/nfts/level4.jpg')
        },
        {
            color: '#500329',
            price: 1600,
            apy: 500,
            name: i18n.t('nfts.dolphin.name'),
            additionalName: i18n.t('nfts.dolphin.sub'),
            image: require('@/assets/images/nfts/level5.jpg')
        },
        {
            color: '#FFC238',
            price: 3200,
            apy: 600,
            name: i18n.t('nfts.shark.name'),
            additionalName: i18n.t('nfts.shark.sub'),
            image: require('@/assets/images/nfts/level6.jpg')
        },
        {
            color: '#FF3838',
            price: 6400,
            apy: 700,
            name: i18n.t('nfts.orca.name'),
            additionalName: i18n.t('nfts.orca.sub'),
            image: require('@/assets/images/nfts/level7.jpg')
        },
        {
            color: '#546A07',
            price: 12800,
            apy: 800,
            name: i18n.t('nfts.whale.name'),
            additionalName: i18n.t('nfts.whale.sub'),
            image: require('@/assets/images/nfts/level8.jpg')
        },
        {
            color: '#A76B01',
            price: 25600,
            apy: 900,
            name: i18n.t('nfts.whale_shark.name'),
            additionalName: i18n.t('nfts.whale_shark.sub'),
            image: require('@/assets/images/nfts/level9.jpg')
        },
        {
            color: '#871FFC',
            price: 51200,
            apy: 1000,
            name: i18n.t('nfts.humpback.name'),
            additionalName: i18n.t('nfts.humpback.sub'),
            image: require('@/assets/images/nfts/level10.jpg')
        }
    ]
}

Vue.prototype.$nftLevels = nftLevels

export default nftLevels
