<template>
    <div class="pt-4 mb-8">
        <div class="text-center">
            <span class="font-bold text-xl lg:text-3xl">Confirm Your Attendance at <span class="text-red">Ocean Summit II</span> no later than 17 May 2024, 23:59 UTC.</span>
        </div>
        <div class="w-full lg:w-2/3 mx-auto">

            <div class="pt-4">
                <span>
                    You <span class="font-bold">MUST</span> confirm your attendance to claim your <span class="font-bold">TICKET ID</span> and participate in Ocean Summit II.
                </span>
            </div>
            <div class="pt-4">
                <div class="flex gap-4 font-bold leading-5 items-center"><img alt="checkmark icon" src="@/assets/images/check.svg" class="w-8" /><span>ONLY Confirm If Attending<span class="text-red pl-1">*</span></span></div>
                <div class="flex gap-4 font-bold leading-5 items-center"><img alt="checkmark icon" src="@/assets/images/x.svg" class="w-8" />DO NOT Confirm If You Are Not Attending</div>
            </div>
            <div class="flex justify-center">
                <custom-button class="mt-4" @click="onConfirmClicked">CONFIRM</custom-button>
            </div>

            <div class="pt-">
                <p class="text-base pt-2"><span class="text-red">*</span> By clicking <span class="font-bold">CONFIRM</span>, you agree to attend Ocean Summit II in person. If you confirm and do not attend you will incur a penalty.</p>
            </div>
        </div>
    </div>
</template>
<script >
    import CustomButton from '@/components/CustomButton.vue'
    import {captureException} from '@sentry/vue'

    export default {
        components: {CustomButton},
        props: {
            myNFT: {
                type: Object
            }
        },
        methods: {
            async onConfirmClicked() {
                this.$store.commit('START_LOADING')
                try {
                    const NFTID = Number(this.$store.getters.selectedNFT.ID)
                    if (!NFTID || isNaN(NFTID)) {
                        this.$printError('There was an error signing message!')
                        return
                    }
                    const signature = await this.$store.state.chainData.rawProvider.request({
                        method: 'personal_sign',
                        params: [`I'm confirming my attendance on OceanSummit II for NFT ID ${NFTID}`, this.$store.state.chainData.address]
                    })


                    const payload = {
                        nft_id: NFTID,
                        signature
                    }

                    await this.$http.post('/api/leaderboard/v1/confirm', payload)
                    this.$emit('load-data')
                } catch (e) {
                    captureException(e)
                } finally {
                    this.$store.commit('STOP_LOADING')
                }

            }
        }

    }

</script>
