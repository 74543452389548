import { ethers } from 'ethers'
import Vue from 'vue'
import chainAddresses from '@/libs/blockchain_contracts/chainAddresses'
import store from '@/store'

const contractAddress = chainAddresses.platform_view
const platformViewABI = require('./ABI/PlatformView.sol/PlatformView.json')

const platformViewContract = () => new ethers.Contract(
        contractAddress,
        platformViewABI,
        store.state.chainData.readProvider
    )

Vue.prototype.$platformViewContract = platformViewContract

export default platformViewContract
