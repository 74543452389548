import { ethers } from 'ethers'
import Vue from 'vue'
import store from '@/store'
import chainAddresses from '@/libs/blockchain_contracts/chainAddresses'

const accountTokenABI = require('./ABI/AccountToken.sol/AccountToken.json')

const accountToken = () => new ethers.Contract(
        chainAddresses.accountToken,
        accountTokenABI,
        store.state.chainData.readProvider
    )

Vue.prototype.$accountToken = accountToken

export default accountToken
