import {BrowserTracing, init, addBreadcrumb} from '@sentry/vue'
import {ExtraErrorData} from '@sentry/integrations'
import Vue from 'vue'

init({
  Vue,
  dsn: 'https://ad07887304d86f24ba30b2d076579862@sentry.metabid.ai/104',
  integrations: [
    new BrowserTracing({
      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ['localhost', 'localhost:8050']
    }),
    new ExtraErrorData()
  ],
  maxBreadcrumbs: 50,
  beforeBreadcrumb(breadcrumb) {
    if (breadcrumb.category === 'ui.click' && breadcrumb.message) {
      const regex = new RegExp('name="(.*?)"')
      const result = regex.exec(breadcrumb.message)

      if (result && result.length >= 2) {
        addBreadcrumb({
          category: 'Custom',
          message: `Clicked button ${result[1]}`,
          level: 'info'
        })
      }

      return null
    } else if (breadcrumb.category === 'xhr') {
      if (breadcrumb.data && breadcrumb.data.url) {
        const url = breadcrumb.data.url

        const urlsToIgnore = []

        if (urlsToIgnore.includes(url)) {
          return null
        }
      }
    }

    return breadcrumb
  },
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0
})
