<template>
    <input
        :ref="id"
        autocapitalize="off"
        :disabled="disabled"
        v-on:blur="(e) => $emit('blur', e)"
        v-on:keyup="(e) => $emit('keyup', e)"
        v-on:input="(e) => $emit('input', e.target.value)"
        v-on:keyup.enter="(e) => $emit('keyup.enter', e)"
        :value="value"
        :placeholder="placeholder"
        :type="type"
    />
</template>
<script>
    import { generateRandomId } from '@/libs/helpers'

    export default {
        props: {
            value: [String, Number],
            placeholder: String,
            type: String,
            disabled: {
                type: Boolean,
                default: false
            }
        },
        methods: {
            focus() {
                if (this.$refs[this.id]) this.$refs[this.id].focus()
            }
        },
        data() {
            return {
                id: ''
            }
        },
        created() {
            this.id = generateRandomId('custom-input')
        }
    }
</script>
<style lang="scss" scoped>
input {
    background-color: white;
    color: black;
    padding: 0.75rem;
    font-size: 1.2rem;
    font-weight: 400;
    border-radius: 10px;
    outline: none;
    transition-duration: 250ms;
}
</style>
