<template>
    <div>
        <label class="switch">
            <input
                type="checkbox"
                :value="value"
                v-model="model"
                :name="name"
            />
            <span class="slider round"></span>
        </label>
    </div>
</template>

<script>
    export default {
        model: {
            prop: 'checked',
            event: 'change'
        },
        props: {
            name: {
                type: String,
                required: true
            },
            checked: {
                type: [Array, Boolean, Number, String],
                default: false
            },
            value: {
                default: null
            }
        },
        computed: {
            model: {
                get() {
                    return this.checked
                },
                set(value) {
                    this.$emit('change', value)
                }
            }
        }
    }
</script>

<style scoped>
.switch {
    position: relative;
    display: inline-block;
    width: 70px;
    height: 34px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #E3A1A3;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.slider:before {
    position: absolute;
    content: '';
    height: 26px;
    width: 26px;
    left: 6px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

input:checked + .slider {
    background: #ba141a;
}

input:focus + .slider {
    box-shadow: 0 0 1px #005c55;
}

input:checked + .slider:before {
    -webkit-transform: translateX(33px);
    -ms-transform: translateX(33px);
    transform: translateX(33px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}
</style>
